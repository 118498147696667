export enum ServicesUrls {
  EXTERNAL_USERS = 'platform/externalusers',
  CLIENT_AREA = 'platform/client_area',
  AUTHENTICATION = 'platform/authentication',
}

export const FrontendUrls = {
  CREATE_PASSWORD: `${window.location.origin}/#/auth/password/create`,
  DEFINE_PASSWORD: `${window.location.origin}/#/auth/password/define`
}
