import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap'
import { ButtonComponent } from './button.component'
import { DropdownItemComponent } from './dropdown-item/dropdown-item.component'
import { DropdownItemEnabledPipe } from './dropdown-item/dropdown-item-enabled.pipe'
import { TranslateModule } from '@ngx-translate/core'

@NgModule({
  declarations: [ButtonComponent, DropdownItemComponent, DropdownItemEnabledPipe],
  imports: [CommonModule, NgbTooltipModule, TranslateModule.forChild()],
  exports: [ButtonComponent]
})
export class ButtonModule {}
