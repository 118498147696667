import { CommonModule } from '@angular/common'
import { HTTP_INTERCEPTORS } from '@angular/common/http'
import {
  DEFAULT_CURRENCY_CODE,
  ErrorHandler,
  ModuleWithProviders,
  NgModule
} from '@angular/core'
import { TranslateModule } from '@ngx-translate/core'
import { CyclicJsonInterceptorModule } from 'app/@core/interceptors/cyclic-json-interceptor/cyclic-json-interceptor.module'
import { HttpInterceptorService } from 'app/@core/interceptors/http-interceptor/http-interceptors.service'
import { LoggedinService } from 'app/@core/security/loggedin.service'
import { ApplicationErrorHandler } from 'app/app.error-handler'
import { LoadingComponent } from './loading/loading.component'
import { CurrencyFormatPipe } from './pipes/currency-format.pipe'
import { EllipsisPipe } from './pipes/ellipsis.pipe'

@NgModule({
  declarations: [LoadingComponent, CurrencyFormatPipe, EllipsisPipe],
  imports: [CommonModule, TranslateModule.forChild()],
  exports: [
    TranslateModule,
    LoadingComponent,
    CurrencyFormatPipe,
    EllipsisPipe
  ]
})
export class SharedModule {
  static forRoot (): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [
        CyclicJsonInterceptorModule,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: HttpInterceptorService,
          multi: true
        },
        { provide: ErrorHandler, useClass: ApplicationErrorHandler },
        { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
        LoggedinService,
        EllipsisPipe
      ]
    }
  }

  static forChild (): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: []
    }
  }
}
