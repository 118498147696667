import 'botfactory-conversation'
import { isDevMode } from '@angular/core'

export const injectBotFactoryConversation = (): void => {
  if (!isDevMode()) {
    const config = {
      element: document.getElementById('botfactory-area'),
      appKey: 'HwoVgK1K/Ad6vnGx8KeW48uOkBWpg/lP8HJCudntnfcRM1KbpRSZhkTFGbyw/f9Q',
      restUrl: 'https://platform.senior.com.br/t/senior.com.br/bridge/1.0/rest/'
    }

    if (window.BotFactory) {
      window.BotFactory.init(config)
    }
  }
}
