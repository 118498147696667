import { CommonModule } from '@angular/common'
import { ModuleWithProviders, NgModule } from '@angular/core'
import { ButtonModule } from './components/button/button.module'
import { InputModule } from './components/input/input.module'

@NgModule({
  declarations: [],
  imports: [ButtonModule, CommonModule, InputModule],
  exports: []
})
export class ThemeModule {
  static forRoot (): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: []
    }
  }
}
