<div
  *ngIf="loading"
  style="margin: 1rem; display: flex; justify-content: center;"
>
  <div class="loadingio-spinner-rolling-begv0hfxt7r">
    <div class="ldio-jbsn38491wr">
      <div></div>
    </div>
  </div>
  <style type="text/css">
    @keyframes ldio-jbsn38491wr {
      0% {
        transform: translate(-50%, -50%) rotate(0deg);
      }
      100% {
        transform: translate(-50%, -50%) rotate(360deg);
      }
    }
    .ldio-jbsn38491wr div {
      width: 25.57px;
      height: 25.57px;
      border: 6.51px solid #00c89b;
      border-top-color: transparent;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .ldio-jbsn38491wr div {
      animation: ldio-jbsn38491wr 1.36986301369863s linear infinite;
    }
    .loadingio-spinner-rolling-begv0hfxt7r {
      background: none;
    }
    .ldio-jbsn38491wr {
      width: 25.57px;
      height: 25.57px;
      position: relative;
      transform: translateZ(0) scale(1);
      backface-visibility: hidden;
      transform-origin: 0 0;
    }
    .ldio-jbsn38491wr div {
      box-sizing: content-box;
    }
  </style>
</div>
