import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { PanelModule } from 'primeng/panel'
import { MainRoutingModule } from './main-routing.module'
import { MainComponent } from './main.component'
import { TranslateModule } from '@ngx-translate/core'

@NgModule({
  declarations: [MainComponent],
  imports: [
    CommonModule,
    BrowserAnimationsModule,
    MainRoutingModule,
    PanelModule,
    TranslateModule.forChild()
  ]
})
export class MainModule {}
