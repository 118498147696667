<router-outlet>
      <div
        *ngIf="loading"
        id="loading-wrapper"
        style="
          display: flex;
          width: 100vw;
          height: 100vh;
          justify-content: center;
          align-items: center;
          background: #ffffffd2;
          position: fixed;
          z-index: 999;
        "
      >
        <ca-loading [loading]="loading"></ca-loading>
      </div>
    </router-outlet>
    <div id="botfactory-area" style="z-index: 99;"></div> 