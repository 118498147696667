import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ControlErrorsComponent } from './control-errors.component'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'

@NgModule({
  declarations: [ControlErrorsComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  exports: [ControlErrorsComponent]
})
export class ControlErrorsModule {}
