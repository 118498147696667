import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MainComponent } from './components/main/main.component';
import { ErrorsPagesModule } from '@shared/errors-pages/errors-pages.module';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  },
  {
    path: 'main',
    component: MainComponent
  },
  {
    path: 'master-home',
    loadChildren: (): Promise<any> =>
      import('./components/master-home/master-home.module').then(m => m.MasterHomeModule)
  },
  {
    path: 'faq',
    loadChildren: (): Promise<any> => import('./components/faq/faq.module').then(m => m.FaqModule)
  },
  {
    path: 'consultant',
    loadChildren: (): Promise<any> =>
      import('./components/dashboard/central-settings/central-settings.module').then(
        m => m.CentralSettingsModule
      )
  },
  {
    path: 'dashboard',
    loadChildren: (): Promise<any> =>
      import('./components/dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'auth',
    loadChildren: (): Promise<any> => import('./components/auth/auth.module').then(m => m.AuthModule)
  },
  {
    path: 'errors',
    loadChildren: (): Promise<ErrorsPagesModule> =>
      import('./shared/errors-pages/errors-pages.module').then(m => m.ErrorsPagesModule)
  },
  {
    path: '**',
    redirectTo: 'errors/404'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      paramsInheritanceStrategy: 'always',
      useHash: true
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
