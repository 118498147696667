import { Injectable } from '@angular/core'
import { Query } from '@datorama/akita'
import { Observable } from 'rxjs'
import { AppState, AppStore } from './app.store'

@Injectable({ providedIn: 'root' })
export class AppQuery extends Query<AppState> {
  constructor(protected store: AppStore) {
    super(store)
  }

  getPageTitle(): Observable<string> {
    return this.select(({ ui }) => {
      if (ui && ui.pageTitle) return ui.pageTitle
    })
  }

  getUserEmail(): Observable<string> {
    return this.select(({ user }) => {
      if (user && user.email) return user.email
    })
  }

  getUserEmailFirstAccess(): Observable<string> {
    return this.select(({ userEmailFirstAccess }) => {
      if (userEmailFirstAccess && userEmailFirstAccess.email) return userEmailFirstAccess.email
    })
  }
}
