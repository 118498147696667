import * as moment from 'moment'

/**
 * Convert from PTBR to EN
 * @param date Date in PtBR format (DD/MM/YYYY)
 * @returns Date in En format (YYYY-MM-DD)
 */
export function ptBRtoEn (date: Date | string): string {
  const parsed = moment(date, 'DD/MM/YYYY').format('YYYY-MM-DD')
  if (!moment(parsed).isValid() || parsed === '') return null
  return parsed
}

/**
 * Convert from EN to PTBR
 * @param date Date in EN format (YYYY-MM-DD)
 * @returns Date in PTBR format (DD/MM/YYYY)
 */
export function EnToPtBR (date: Date | string): string {
  if (!date || !moment(date).isValid()) return null
  const parsed = moment(date).format('DD/MM/YYYY')
  return parsed
}

/**
 * Parse date to timeAgo
 * @param date Date in format: 2019-07-29T12:12:32.586Z
 * @returns 8 years ago, 10 hours ago...
 */
export function DateToTimeago (date: Date, lang = 'pt-br') {
  moment.locale(lang)
  return moment(date).fromNow()
}

/**
 * Today date
 * @returns Date in En format (YYYY-MM-DD)
 */
export function TodayToEn () {
  return moment(new Date()).format('YYYY-MM-DD')
}

/**
 * Today date
 * @returns Date in pt-BR format (DD-MM-YYYY)
 */
 export function TodayToPtBR () {
  return moment(new Date()).format('DD-MM-YYYY')
}
