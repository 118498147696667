// eslint-disable-next-line semi
import { FormArray } from '@angular/forms';

export interface ClientData {
  id?: string;
  integrationCode: string;
  projectCode?: string;
  companyName: string;
  trade: string;
  primaryEmail?: string;
  billingEmail: string;
  legalDocumentEmail: string;
  contractEmail: string;
  website?: string;
  addresses: ClientDataAddress[];
  contacts?: ClientDataContact[];
  phones: ClientDataPhone[];
  isChannel?: boolean;
  hasSaas?: boolean;
  allowed: boolean;
}

export interface ContactData {
  contacts: ClientDataContact[];
}

export interface ClientDataUpgrade {
  id?: string;
  allowed: boolean;
  integrationCode: string;
  companyName: string;
  trade: string;
  primaryEmail?: string;
  billingEmail: string;
  legalDocumentEmail: string;
  contractEmail: string;
  website?: string;
  addresses: ClientDataAddress[];
  contacts: ClientDataContact[];
  phones: ClientDataPhone[];
  isGlobalTec?: boolean;
  isJobconvo?: boolean;
  isGatec?: boolean;
  isKonviva?: boolean;
  isAllJobconvo?: boolean;
  isAllGatec?: boolean;
  isAllKonviva?: boolean;
  hasMenuServicePermission?: boolean;
  urlFlowReset?: string;
  hasSaas?: boolean;
  isChannel?: boolean;
  bloqueioCadastro?: string;
  idClientServiceNow?: string;
  numberOfProjectsServiceNow?: number;
}

export interface UrlFlowReset {
  urlFlowReset: string;
}

export interface SaasInfo {
  hasSaas: boolean;
}

export interface BloqueioCadastroInfo {
  isChannel: boolean;
  bloqueioCadastro: string;
}

export interface ServiceNowInfo {
  idClientServiceNow: string;
  numberOfProjectsServiceNow: number;
}

export interface ClientDataPhone {
  id?: string;
  clientId?: string;
  type: ClientDataPhoneType;
  phone: string;
}

export enum ClientDataPhoneType {
  COMERCIAL = 'COMERCIAL',
  RESIDENTIAL = 'RESIDENTIAL',
  FINANCIAL = 'FINANCIAL'
}

export interface ClientDataAddress {
  id?: string;
  cpfCnpj: string;
  type: ClientDataAddressType;
  address: string;
  number?: string;
  complement?: string;
  neighborhood: string;
  landmark?: string;
  zipCode: string;
  city: string;
  state: string;
  stateRegistration: string;
  municipalRegistration?: string;
}

export enum ClientDataAddressType {
  COMERCIAL = 'COMERCIAL',
  SHIPPING = 'SHIPPING',
  BILLING = 'BILLING'
}

export interface ClientDataContact {
  id?: string;
  name: string;
  email: string;
  birthDate?: string;
  department?: string;
  role?: string;
  status?: boolean;
  phones: ClientDataContactPhone[];
}

export interface ClientDataContactPhone {
  id?: string;
  clientId?: string;
  type: ClientDataPhoneType;
  phone: string;
  phoneExtension?: string;
}

export interface ClientDataForm {
  id?: string;
  companyName: string;
  trade: string;
  cpfCnpj?: string;
  integrationCode: string;
  projectCode?: string;
  stateRegistration: string;
  municipalRegistration?: string;
  isChannel?: boolean;
  primaryEmail: string;
  billingEmail: string;
  legalDocumentEmail: string;
  contractEmail: string;

  website?: string;

  phones?: ClientDataPhone[];
  phone1: { id?: string; phone: string };
  phone2?: { id?: string; phone: string };

  addresses?: ClientDataAddress[];
  address: ClientDataAddressForm;
  contacts?: ClientDataFormContact[];
  allowed: boolean;
}

export interface ClientDataAddressForm {
  id?: string;
  zipCode: string;
  address: string;
  number: string;
  complement?: string;
  neighborhood: string;
  landmark: string;
  city: string;
  state: string;
}

export interface ClientDataFormContact {
  id?: string;
  name: string;
  email: string;
  department?: string;
  role?: string;
  birthDate?: string;
  status?: boolean;
  phones?: ClientDataFormContactPhone[];
}

export interface ClientDataFormContactPhone {
  id?: string;
  phone: string;
  phoneExtension?: string;
}

export interface OnlyActiveContacts {
  value: boolean;
  contacts?: FormArray;
}

export interface RolesCRM {
  id: string;
  nome: string;
}
