import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from '@environments/environment';
import { CookieKeys } from '@shared/keys';
import { Utils } from '@shared/utils';
import {
  ClientDataUpgrade,
  SaasInfo,
  ServiceNowInfo,
  UrlFlowReset
} from 'app/components/dashboard/client-data/client-data.model';
import { ClientDataService } from 'app/components/dashboard/client-data/client-data.service';
import { AppQuery } from 'app/state';
import { CookieService } from 'ngx-cookie-service';
import { of, forkJoin, Observable, throwError } from 'rxjs';
import { catchError, concatMap, filter, map, pluck, shareReplay, tap } from 'rxjs/operators';
import { ServicesUrls } from '../servicesUrls';
import { AvailableClient, Loggedin, MasterOrSeniorUserInfo, UserInfoAuthorized } from './loggedin.model';
import { LoggedinQuery } from './loggedin.query';
import { LoggedinStore } from './loggedin.store';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class LoggedinService {
  lastUrl: string;
  userAuthorized$: Observable<Loggedin>;
  userMasterSenior: MasterOrSeniorUserInfo = {
    id: '',
    isAdmin: false,
    isMaster: false,
    isSenior: false
  };

  constructor(
    private cookie: CookieService,
    private router: Router,
    private http: HttpClient,
    private store: LoggedinStore,
    public loggedInQuery: LoggedinQuery,
    private appQuery: AppQuery,
    private readonly clientDataService: ClientDataService,
    private readonly toastr: ToastrService,
    public readonly translate: TranslateService
  ) {
    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => (this.lastUrl = e.url));
    this.initializeUserAuthorized();
    this.loggedInQuery.mustRefreshPersonalData$.subscribe(() => {
      this.initializeUserAuthorized();
    });
  }

  private _userInfoPath = 'queries/userInfo';
  private client$: Observable<ClientDataUpgrade>;

  public set userInfoPath(p: string) {
    this._userInfoPath = p;
    this.validIsSenior();
    this.initializeUserAuthorized();
  }

  public get userInfoPath() {
    return this._userInfoPath;
  }

  public validIsSenior() {
    this.appQuery.getUserEmailFirstAccess().subscribe(email => {
      this.masterOrSeniorUserInfo(email || '').subscribe(res => (this.userMasterSenior = res));
    });
  }

  public getClient(client: string): Observable<ClientDataUpgrade> {
    // Chamada essencial - caso ocorra erro não conecta no portal
    const clientData$ = this.clientDataService.getClientData(client).pipe(
      catchError(error => {
        this.backToLogin();
        return throwError(error);
      })
    );

    // Chamada essencial - caso ocorra erro não conecta no portal
    const channel$ = this.clientDataService.getChannel(client).pipe(
      catchError(error => {
        this.backToLogin();
        return throwError(error);
      })
    );

    return clientData$.pipe(
      concatMap(clientData =>
        channel$.pipe(
          concatMap(channel =>
            forkJoin([
              // Retornando o resultado de clientData$ para o forkJoin
              of(clientData),
              // Retornando o resultado de channel$ para o forkJoin
              of(channel),
              // Chamada não essencial - deve conectar ao portal mesmo com erro nessa chamada
              this.clientDataService.getFlowReset(client).pipe(
                catchError(() => {
                  // Exibindo mensagem de erro
                  this.showWarningMessage();
                  // Retornando objeto vazio
                  return of({} as UrlFlowReset);
                })
              ),
              // Chamada não essencial - deve conectar ao portal mesmo com erro nessa chamada
              this.clientDataService.getSaas(client).pipe(
                catchError(() => {
                  // Exibindo mensagem de erro
                  this.showWarningMessage();
                  // Retornando objeto vazio
                  return of({} as SaasInfo);
                })
              ),
              // Chamada não essencial - deve conectar ao portal mesmo com erro nessa chamada
              this.clientDataService.getServiceNowProject(client).pipe(
                catchError(() => {
                  // Exibindo mensagem de erro
                  this.showWarningMessage();
                  // Retornando objeto vazio
                  return of({} as ServiceNowInfo);
                })
              )
            ]).pipe(
              map(([respClientData, respChannel, respFlowReset, respSaas, respServiceNowProject]) => {
                return {
                  ...respClientData,
                  ...respChannel,
                  ...respFlowReset,
                  ...respSaas,
                  ...respServiceNowProject
                };
              })
            )
          )
        )
      ),
      shareReplay(1)
    );
  }

  private backToLogin(): void {
    // mostra mensagem de erro
    this.toastr.error(
      this.translate.instant('platform.client_area.toast_warn_message_instability'),
      this.translate.instant(' '),
      { disableTimeOut: true, closeButton: true }
    );
    // retornando para tela de login
    this.handleLogin();
  }

  private showWarningMessage(): void {
    this.toastr.warning(
      this.translate.instant('platform.client_area.toast_warn_message_instability'),
      this.translate.instant(' '),
      { disableTimeOut: true, closeButton: true }
    );
  }

  public changeCompany(client) {
    this.client$ = this.getClient(client);
    const array = this.router.url.split('/').filter(i => i !== 'dashboard' && i !== '/' && i !== '');
    this.client$.subscribe(c => {
      if (this.checkClientPermissions(array, c.isChannel)) {
        this.router.navigate(['/dashboard/home']);
      }
    });
    return this.clientInfo();
  }

  public clientInfo(): Observable<ClientDataUpgrade> {
    if (!this.client$) {
      return of(null);
    }
    return this.client$;
  }

  public clientChannel(): Observable<boolean> {
    if (!this.client$) {
      return of(false);
    }
    return this.client$.pipe(
      map(e => {
        return e.isChannel;
      })
    );
  }

  public isLogged(): boolean {
    return !!this.cookie.get(CookieKeys.TOKEN) || !!this.cookie.get(CookieKeys.CONSULTANT);
  }

  public userAuthorized(): Observable<Loggedin> {
    return this.userAuthorized$;
  }

  public masterSenior(): boolean {
    return this.userMasterSenior.isSenior;
  }

  public switchEnterprise(enterpriseId: string): Observable<AvailableClient> {
    return this.http
      .post<{ client: AvailableClient }>(`${ServicesUrls.CLIENT_AREA}/actions/setCurrentClient`, {
        clientId: enterpriseId
      })
      .pipe(
        map(({ client }) => client),
        tap(client => {
          this.store.switchCurrentClient(enterpriseId);
        })
      );
  }

  handleLogin(path: string = this.lastUrl): void {
    this.router.navigate(['/auth/login'], {
      queryParams: {
        redirectTo: path
      }
    });
  }

  private masterOrSeniorUserInfo(value): Observable<MasterOrSeniorUserInfo> {
    const header = {
      email: value
    };
    return this.http
      .post<MasterOrSeniorUserInfo>(`${ServicesUrls.CLIENT_AREA}/queries/masterOrSeniorUserInfo`, header)
      .pipe(shareReplay(1), pluck('user'));
  }

  private initializeUserAuthorized(): void {
    this.userAuthorized$ = this.http
      .get<UserInfoAuthorized>(
        `${environment.restNotLogged}/${ServicesUrls.CLIENT_AREA}/${this.userInfoPath}`
      )
      .pipe(
        map(({ user }) => user),
        tap(user => {
          if (Utils.isNil(user.clients) || user.clients.length <= 0) {
            return;
          }
          this.store.addAvailableEnterprises(user);
          this.client$ = this.getClient(this.loggedInQuery.getActiveId());
        }),
        shareReplay(1)
      );
  }

  private checkClientPermissions(route: string[], isChannel: boolean): boolean {
    let canContinuos = false;

    if (isChannel) {
      route.forEach(i => {
        if (i === 'billing' || i === 'contract-upgrade' || i === 'my-contract') {
          canContinuos = true;
        }
      });
    }

    return canContinuos;
  }
}
