export class Utils {
  public static stringIsEmpty(val: string): boolean {
    if (!val) {
      return true
    }

    return typeof val === 'string' ? val.trim().length <= 0 : false
  }

  public static arrayIsEmpty(arr: any[]): boolean {
    return !arr || arr.length <= 0
  }

  public static isNil(a: any): boolean {
    return a === null || a === undefined
  }
}
