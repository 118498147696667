<p-panel>
  <p-header>
    <span>{{ 'platform.client_area.main_header' | translate }}</span>
  </p-header>

  <div class="ui-fluid">
    <div class="ui-g">
      <div class="ui-g-12" [routerLink]="['/dashboard']">{{ 'platform.client_area.main_dashboard' | translate }}</div>
      <div class="ui-g-12" [routerLink]="['/auth']">{{ 'platform.client_area.main_autentication' | translate }}</div>
    </div>
  </div>
</p-panel>
