import { Injectable } from '@angular/core'
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita'
import { Dashboard } from './dashboard.model'

export interface DashboardState extends EntityState<Dashboard> {
  ui: {
    burger?: boolean
    burgerOpened?: boolean
  }
}

const initialState = {
  ui: { burgerOpened: false }
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'dashboard'
})
export class DashboardStore extends EntityStore<DashboardState> {
  constructor () {
    super(initialState)
  }

  setBurgerMenu (enabled: boolean): void {
    this.update(entity => ({ ui: { ...entity.ui, burger: enabled } }))
  }

  toggleBurgerMenu (state: boolean): void {
    this.update(entity => ({ ui: { ...entity.ui, burgerOpened: state } }))
  }
}
