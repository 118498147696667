import { Injectable } from '@angular/core'
import { Order, QueryConfig, QueryEntity } from '@datorama/akita'
import { Observable, Subject } from 'rxjs'
import { AvailableClient } from './loggedin.model'
import { LoggedinState, LoggedinStore } from './loggedin.store'

@Injectable({ providedIn: 'root' })
@QueryConfig({
  sortByOrder: Order.DESC
})
export class LoggedinQuery extends QueryEntity<LoggedinState> {

  public mustRefreshPersonalData$ = new Subject();

  constructor(protected store: LoggedinStore) {
    super(store)
  }

  getLoggedUser(): Observable<AvailableClient> {
    return this.selectActive() as Observable<AvailableClient>
  }

  getEnterprises(): Observable<AvailableClient[]> {
    return this.selectAll()
  }

  authErrors(): Observable<{ status?: number }> {
    return this.select(({ ui }) => ui.authError)
  }
}
