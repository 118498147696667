import { Injectable } from '@angular/core'
import { QueryEntity } from '@datorama/akita'
import { Observable } from 'rxjs'
import { DashboardState, DashboardStore } from './dashboard.store'

@Injectable({ providedIn: 'root' })
export class DashboardQuery extends QueryEntity<DashboardState> {
  constructor (protected store: DashboardStore) {
    super(store)
  }

  isBurgerMenu (): Observable<boolean> {
    return this.ui.select(({ ui }) => ui.burger)
  }

  burgerOpened (): Observable<boolean> {
    return this.ui.select(({ ui }) => ui.burgerOpened)
  }
}
