export enum CookieKeys {
  PORTAL_URL = 'com.senior.portal.url',
  BASE_URL = 'com.senior.base.url',
  TOKEN = 'com.senior.token',
  TOKEN_PAU = 'com.senior.pau.token',
  SERVICES = 'com.senior.services.url',
  CONSULTANT = 'authConsultant'
}

export const CookieDomain = '.senior.com.br';
