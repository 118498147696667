import { Component, OnInit } from '@angular/core';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { injectGoogleAnalytics } from '@shared/GoogleAnalytics';
import { LoggedinService } from './@core/security';
import { DashboardQuery, DashboardStore } from './components/dashboard/state';
import { injectBotFactoryConversation } from './shared/bot-factory/BotFactory';
import { AppQuery } from './state';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ca-root',
  template: `<router-outlet>
      <div
        *ngIf="loading"
        id="loading-wrapper"
        style="
          display: flex;
          width: 100vw;
          height: 100vh;
          justify-content: center;
          align-items: center;
          background: #ffffffd2;
          position: fixed;
          z-index: 999;
        "
      >
        <ca-loading [loading]="loading"></ca-loading>
      </div>
    </router-outlet>
    <div id="botfactory-area" style="z-index: 99;"></div> `,
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  loading = false;

  constructor(
    private translate: TranslateService,
    private router: Router,
    private dashboardQuery: DashboardQuery,
    private dashboardStore: DashboardStore,
    private appQuery: AppQuery,
    private readonly toastr: ToastrService
  ) {
    translate.setDefaultLang('pt-BR');
    translate
      .use('pt-BR')
      .toPromise()
      .then(() => this.handlePageTitle());

    injectGoogleAnalytics();
    injectBotFactoryConversation();
  }

  private showMessageMaintenance = false;

  ngOnInit(): void {
    this.handleBurgerMenuStates();
    this.handleLoadingByRoute();
    this.toastr.warning(
      'No dia 16/11 das 10h até 12h, teremos uma manutenção no Portal do Cliente. Durante o período o sistema poderá ficar indisponível.',
      'Manutenção programada',
      { disableTimeOut: true, closeButton: true }
    );
  }

  handlePageTitle(): void {
    this.appQuery.getPageTitle().subscribe(title => {
      if (title) {
        document.title = `${this.translate.instant(title)} | ${this.translate.instant(
          'platform.client_area.seo_title'
        )}`;
      }
    });

    if (this.showMessageMaintenance) {
      this.messageMaintenance();
    }
  }

  handleLoadingByRoute(): void {
    this.router.events.subscribe(
      e => {
        this.closeBurgerMenu();
        if (e instanceof NavigationStart) this.loading = true;
        if (e instanceof NavigationEnd || e instanceof NavigationError || e instanceof NavigationCancel) {
          this.loading = false;
        }
      },
      () => (this.loading = false),
      () => (this.loading = false)
    );
  }

  closeBurgerMenu(): void {
    this.dashboardStore.toggleBurgerMenu(false);
  }

  handleBurgerMenuStates(): void {
    this.dashboardQuery.select().subscribe(({ ui }) => this.setScroll(!ui.burgerOpened));
  }

  private messageMaintenance() {
    this.toastr.warning(
      this.translate.instant('platform.client_area.toast_warn_message_instability'),
      this.translate.instant('platform.client_area.toast_warn_title'),
      { disableTimeOut: true, closeButton: true }
    );
  }

  setScroll(enabled = true): void {
    if (enabled) {
      document.body.style.overflow = 'unset';
    } else {
      document.body.style.overflow = 'hidden';
    }
  }
}
