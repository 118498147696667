import { Injectable } from '@angular/core'
import { Store, StoreConfig } from '@datorama/akita'

export interface AppState {
  ui: {
    pageTitle?: string
  }
  user: {
    email?: string
  }
  userEmailFirstAccess: {
    email?: string
  }
}

@Injectable({ providedIn: 'root' })
@StoreConfig({ name: 'app' })
export class AppStore extends Store<AppState> {
  constructor() {
    super({})
  }

  setPageTitle(translateKey: string): void {
    this.update(entity => ({
      ...entity,
      ui: { ...entity.ui, pageTitle: translateKey }
    }))
  }

  setUserEmail(email: string): void {
    this.update(entity => ({
      ...entity,
      user: { ...entity.user, email }
    }))
  }

  setUserEmailFirstAccess(email: string): void {
    this.update(entity => ({
      ...entity,
      userEmailFirstAccess: { ...entity.userEmailFirstAccess, email }
    }))
  }
}
