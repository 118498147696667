import { HttpErrorResponse } from '@angular/common/http'
import { ErrorHandler, Injectable, Injector, NgZone } from '@angular/core'
import { Router } from '@angular/router'

@Injectable({
  providedIn: 'root'
})
export class ApplicationErrorHandler extends ErrorHandler {
  constructor (private zone: NgZone, private injector: Injector) {
    super()
  }

  handleError (errorResponse: HttpErrorResponse | any): void {
    const router = this.injector.get(Router)
    if (errorResponse instanceof HttpErrorResponse) {
      // const message = errorResponse.error.message
      this.zone.run(() => {
        const actions = {
          403: () => router.navigate(['/errors/forbidden'])
        }
        return actions[errorResponse.status]
      })
    }
    super.handleError(errorResponse)
  }
}
