import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ReactiveFormsModule, FormsModule } from '@angular/forms'
import { SuccessMessageComponent } from './success-message.component'
import { TranslateModule } from '@ngx-translate/core'

@NgModule({
  declarations: [SuccessMessageComponent],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, TranslateModule.forChild()],
  exports: [SuccessMessageComponent]
})
export class SuccessMessageModule {}
