import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from '@angular/common/http'
import { Injectable } from '@angular/core'
import { service } from '@seniorsistemas/senior-platform-data'
import { CookieKeys } from '@shared/keys'
import { CookieService } from 'ngx-cookie-service'
import { defer, forkJoin, from, Observable } from 'rxjs'
import { mergeMap } from 'rxjs/operators'
import { LoggedinService } from '../../security/loggedin.service'

@Injectable()
export class RestUrl {
  get (): Observable<string> {
    return defer(() => from(service.getRestUrl()))
  }
}

@Injectable()
export class HttpInterceptorService implements HttpInterceptor {
  constructor (
    private cookie: CookieService,
    private loggedInService: LoggedinService,
    private restUrl: RestUrl
  ) {}

  intercept (
    originalReq: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      this.loggedInService.isLogged() &&
      !originalReq.url.startsWith('./locale/')
    ) {
      const getRestUrl = this.restUrl.get()
      const token = decodeURIComponent(this.cookie.get(CookieKeys.TOKEN))
      const getAuthHeader = JSON.parse(token).access_token

      return forkJoin([getRestUrl, getAuthHeader]).pipe(
        mergeMap((values: any) => {
          const [bridgeUrl, authHeader] = values
          const url = this.notIncludesSlash(originalReq)
            ? bridgeUrl + originalReq.url
            : undefined

          const headers = authHeader
            ? originalReq.headers.set(
              'Authorization',
                `Bearer ${getAuthHeader}`
            )
            : undefined

          const request = originalReq.clone({
            url,
            headers
          })
          return next.handle(request)
        })
      )
    }
    return next.handle(originalReq)
  }

  notIncludesSlash (req: HttpRequest<any>): boolean {
    return !req.url.includes('://')
  }
}
