import { environment as def } from './environment.default'

export const environment: any = {
  ...def,
  production: false,

  restNotLogged:
    'https://cloud-leaf.senior.com.br/t/senior.com.br/bridge/1.0/rest',
  restNotLoggedAnonymous:
    'https://cloud-leaf.senior.com.br/t/senior.com.br/bridge/1.0/anonymous/rest',
  externalLoginServlet:
    'https://cloud-leaf.senior.com.br/auth/ExternalLoginServlet'
}
