import { APP_BASE_HREF, registerLocaleData } from '@angular/common'
import { HttpClient, HttpClientModule } from '@angular/common/http'
import { NgModule, LOCALE_ID } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { NG_ENTITY_SERVICE_CONFIG } from '@datorama/akita-ng-entity-service'
import { AkitaNgRouterStoreModule } from '@datorama/akita-ng-router-store'
import { AkitaNgDevtools } from '@datorama/akita-ngdevtools'
import { NgbModule } from '@ng-bootstrap/ng-bootstrap'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { CookieService } from 'ngx-cookie-service'
import { ToastrModule } from 'ngx-toastr'
import { Observable, of } from 'rxjs'
import { environment } from '../environments/environment'
import { HeadersInterceptor } from './@core/interceptors/custom-headers-interceptor/custom-headers-interceptor.module'
import { RestUrl } from './@core/interceptors/http-interceptor/http-interceptors.service'
import { ThemeModule } from './@theme/theme.module'
import { AppComponent } from './app.component'
import { AppRoutingModule } from './app.routing'
import { MainModule } from './components/main/main.module'
import { SharedModule } from './shared/shared.module'

import localePt from '@angular/common/locales/pt'

import { NgxMaskModule, IConfig } from 'ngx-mask'
registerLocaleData(localePt)

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './locale/', '.json')
}

export class CustomRestUrl {
  get(): Observable<any> {
    return of((environment as any).restUrl)
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    AkitaNgRouterStoreModule.forRoot(),
    environment.production ? [] : AkitaNgDevtools.forRoot({ name: 'client-area' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    HeadersInterceptor,
    HttpClientModule,
    MainModule,
    NgbModule,
    SharedModule.forRoot(),
    ToastrModule.forRoot({
      progressBar: true,
      progressAnimation: 'increasing'
    }),
    ThemeModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    NgxMaskModule.forRoot()
  ],
  providers: [
    CookieService,
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
    {
      provide: RestUrl,
      useFactory: (): CustomRestUrl | RestUrl =>
        !environment.production && (environment as any).restUrl
          ? new CustomRestUrl()
          : new RestUrl()
    },
    {
      provide: NG_ENTITY_SERVICE_CONFIG,
      useValue: { baseUrl: 'https://jsonplaceholder.typicode.com' }
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
