import { CommonModule } from '@angular/common'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { RouterModule } from '@angular/router'
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap'
import { TranslateModule } from '@ngx-translate/core'
import { ControlErrorsModule } from '@shared/control-errors/control-errors.module'
import { SuccessMessageModule } from '@shared/success-message/success-message.module'
import { IConfig, NgxMaskModule } from 'ngx-mask'
import { InputComponent } from './input.component'

export const options: Partial<IConfig> | (() => Partial<IConfig>) = {
  clearIfNotMatch: false
}

@NgModule({
  declarations: [InputComponent],
  imports: [
    CommonModule,
    ControlErrorsModule,
    SuccessMessageModule,
    FormsModule,
    NgbTooltipModule,
    NgxMaskModule.forRoot(),
    ReactiveFormsModule,
    RouterModule,
    TranslateModule.forChild()
  ],
  exports: [InputComponent, NgxMaskModule]
})
export class InputModule {}
