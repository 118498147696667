import { Injectable, NgModule } from '@angular/core'
import { Observable } from 'rxjs/Observable'
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HTTP_INTERCEPTORS
} from '@angular/common/http'
import { CookieService } from 'ngx-cookie-service'
import { environment } from '@environments/environment'

@Injectable()
export class AddHeadersInterceptor implements HttpInterceptor {
  constructor(private cookie: CookieService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const tenantCookie = this.cookie.get('ca-tenant')
    const tenantDomainCookie = this.cookie.get('ca-tenant-domain')

    const tenant = tenantCookie ? tenantCookie : !environment.production ? 'tihmlpccombr' : 'senior'
    const tenantDomain = tenantDomainCookie ? tenantDomainCookie : !environment.production ? 'tihmlpc.com.br' : 'senior.com.br'

    const clone = req.clone({
      headers: req.headers
        .set('X-Tenant', tenant)
        .set('X-TenantDomain', tenantDomain)
    })
    return next.handle(clone)
  }
}

@NgModule({
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AddHeadersInterceptor,
      multi: true
    }
  ]
})
export class HeadersInterceptor { }
