import { Injectable } from '@angular/core'
import { EntityState, EntityStore, StoreConfig } from '@datorama/akita'
import { AvailableClient, Loggedin } from './loggedin.model'

export interface LoggedinState extends EntityState<AvailableClient> {
  ui: {
    authError?: {
      status?: number
    }
  }
}

const initialState = {
  ui: { authError: { status: null } }
}

@Injectable({ providedIn: 'root' })
@StoreConfig({
  name: 'loggedin'
})
export class LoggedinStore extends EntityStore<LoggedinState> {
  constructor () {
    super(initialState, {
      resettable: true
    })
  }

  addAvailableEnterprises (user: Loggedin): void {
    if (user && user.clients) {
      this.reset()
      user.clients.forEach(({ id, trade, companyName, cpfCnpj }) => {
        this.upsert(id, { companyName, trade, cpfCnpj });
      })

      this.setCurrentEnterprise(user)
    }
  }

  setCurrentEnterprise (user: Loggedin): void {
    const currentClientId = typeof user.currentClient === 'string' ? user.currentClient : user.currentClient?.id;
    this.setActive(currentClientId || user.clients[0].id)
  }

  switchCurrentClient (id: string): void {
    this.setActive(id)
  }

  setAuthError (status = null): void {
    this.update(() => ({
      ui: { authError: { status } }
    }))
  }
}
